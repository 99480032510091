<!--三务公开  -->
<template>
  <div class="">
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @action-column-click="actionColumnClick"
      @bulkDeletion="deleteSecurityPersonnel"
      :searchActions="searchActions"
      :dynamicSlotNames="['gridId', 'gridId']"
      @add="add"
      @export="expor"
      @exportAll="exportAll"
      @reset="reset"
      @searchlist="searchlist"
      @pop-confirm="popConfirm"
      :scroll="{ y: '45vh', x: '80vw' }"
    >
      <!--  :showToolBar="true"  表格列设置 -->
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          change-on-select
          :show-search="{ filter }"
          placeholder="请选择所属网格"
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          :model="cuurentAreaCode"
          v-model="cuurentAreaCode1"
        />
      </div>
      <div slot="type" slot-scope="{ record }">
        <div v-if="record.type === '1'">党务公开</div>
        <div v-else-if="record.type === '2'">财务公开</div>
        <div v-else-if="record.type === '3'">政务公开</div>
      </div>
    </ax-table>
    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="新增三务公开"
      :visible.sync="dialogAdd"
      width="50%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable"
    /></el-dialog>
    <!-- 编辑跳转弹窗 -->
    <addedit ref="edit" @refreshTable="refreshTable"></addedit>
  </div>
</template>
    <script>
import api from "./api";
import addPage from "./addPage.vue";
import addedit from "./addedit.vue";
const options = [
  { label: "党务公开", value: "1" },
  { label: "财务公开", value: "2" },
  { label: "政务公开", value: "3" },
];
const searchForm = [
  {
    label: "标题",
    type: "inputSearch",
    model: "affairsTitle",
    options: { placeholder: "标题" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属社区",
    type: "inputSearch",
    model: "communityName",
    options: { placeholder: "所属社区" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "所属网格" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "类型",
    type: "select",
    model: "type",
    options: { options: options, placeholder: "类型" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, addedit },
  data() {
    //这里存放数据
    return {
      api,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [
        { name: "add", text: "新增" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
        { name: "bulkDeletion", text: "批量删除", type: "#FA5959" },
      ],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "标题",
            dataIndex: "affairsTitle",
            ellipsis: true,
            width: 80,
          },
          {
            title: "所属社区",
            dataIndex: "communityName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "类型",
            dataIndex: "type",
            ellipsis: false,
            width: 80,
          },
          {
            title: "浏览次数",
            dataIndex: "count",
            ellipsis: false,
            width: 80,
          },
          {
            title: "发布人",
            dataIndex: "updateBy",
            ellipsis: false,
            width: 80,
          },
          {
            title: "发布时间",
            dataIndex: "updateTime",
            ellipsis: false,
            width: 120,
          },
        ],
        true,
        {
          width: 120,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#2A5CFF",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },

            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  link: true,
                  type: "#FA5959",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: {},
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },

    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
      this.cuurentAreaCode = value[value.length - 1];
      this.cuurentAreaCode1 = value;
    },
    //重置
    reset(e) {
      this.cuurentAreaCode = "";
      this.cuurentAreaCode1 = [];
      if (this.$refs.cascader) {
        this.$refs.cascader.$refs.input.value = "";
      }
      this.gridId = ""; // 清空 gridId 参数
    },

    // 在搜索方法中根据情况传入或不传入 gridId 参数
    searchlist(e) {
      const formValues = { ...e.formValues };
      e.formValues.gridId = this.gridId || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    // 导出全部
    exportAll() {
      const values = JSON.parse(JSON.stringify({ kind: 1 }));
      api.export({
        fileName: "学校信息.xls",
        params: values,
      });
    },
    // 导出筛选
    expor() {
      this.searchForm.formValues.gridId = this.gridId || undefined;
      this.searchForm.formValues.kind = 1;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      api.export({
        fileName: "三务公开.xls",
        params: values,
      });
    },
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //编辑
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.ids(that.selectedRowKeys.join(","));
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.roleTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>